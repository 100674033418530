import * as React from "react"
import { Link } from "gatsby"
import "../css/bootstrap.min.css"
import "../css/style.css"

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Careers at Bajaj Electricals</title>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand logo" href="/"><img src="/images/logo.png" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="hamburger">
                    <span />
                  </div>
                </button>
                <div className="collapse navbar-collapse" id="navbarsExample09">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <a className="nav-link" href="/">Careers</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/">Professionals</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/">Students &amp; Graduates</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/">Life @ Bajaj Electricals</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <section className="banner-section">
        <figure className="spotlight_img positionRelative">
          <picture>
            <source media="(max-width: 767px)" srcSet="/images/banner-mobile.jpg" alt="" width={1920} height={1256} />
            <source media="(max-width: 1920px)" srcSet="/images/banner.jpg" alt="" width={1920} height={1256} />
            <img src="/images/banner.jpg" alt="" width={1920} height={1256} />
          </picture>
        </figure>
        <div className="banner-caption">
          <h4>Do What You</h4>
          <h1>Love</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vel lacus quis.</p>
          <form>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="SEARCH JOBS" />
              <button><a href="/job-search/"><img src="/images/search.png" /></a></button>
            </div>
          </form>
        </div>
      </section>
      <section className="banner-card">
        <div className="row m-0">
          <div className="col-sm-5 p-0">
            <div className="row m-0 bg-blue align-items-center">
              <div className="col-sm-5 p-0">
                <img src="/images/banner-img-01.jpg" alt="" width height />
              </div>
              <div className="col-sm-7 p-0">
                <div className="owl-carousel banner-updates">
                  <div className="item">
                    <h6>Update</h6>
                    <h4>John Doe joins Bajaj Electricals as Marketing Head</h4>
                    <p>12th March</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-7 p-0 card-top">
            <div className="row m-0">
              <div className="col-sm-5 p-5 bg-oange">
                <div className="at-bajaj">
                  <img src="/images/at-bajaj.png" alt="" width height />
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vel lacus quis diam aliquam maximus. Nullam egestas risus interdum lectus porta ornare.</p>
                </div>
              </div>
              <div className="col-sm-7 p-0">
                <div className="owl-carousel banner-we-at">
                  <div className="item">
                    <img src="/images/we-at-slider01.jpg" alt="" width height />
                    <h5>We at bajaj</h5>
                  </div>
                </div>
                <div className="amazing">
                  <h2>Amazing people <br className="hiddenMobile" />together to make <br className="hiddenMobile" />amazing things</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="card-layout">
        <div className="row m-0">
          <div className="col-sm-5 p-0">
            <div className="card">
              <img src="/images/get-bg.jpg" alt="" width height />
              <div className="center-div">
                <img src="/images/get.png" className="mb-2" alt="" width height />
                <p className="color-black">Lorem ipsum dolor sit amet, consectetur elit. In vel lacus quis diam aliquam maximus. Nullam egestas risus interdum lectus porta ornare.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-7 p-0">
            <div className="row m-0">
              <div className="col-sm-6 p-0">
                <div className="card">
                  <img src="/images/image01.jpg" alt="" width height />
                  <div className="center-div">
                    <a href="/">
                      <h2>Students &amp; <br />Recent <br />Graduates</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur elit. In vel lacus quis diam aliquam maximus. Nullam egestas risus interdum
                        lectus porta ornare.</p>
                      <span className="go-btn">
                        <img src="/images/go-arrow.png" alt="" width height />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 p-0">
                <div className="card">
                  <img src="/images/image02.jpg" alt="" width height />
                  <div className="center-div">
                    <a href="/">
                      <h2>Experienced <br />Professional</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur elit. In vel lacus quis diam aliquam maximus. Nullam egestas risus interdum
                        lectus porta ornare.</p>
                      <span className="go-btn">
                        <img src="/images/go-arrow-blue.png" alt="" width height />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div id="testimonial" className="owl-carousel">
                <div className="item">
                  <div className="row testimonial-item align-items-center">
                    <div className="col-sm-1" />
                    <div className="col-sm-4 ml-4">
                      <div className="testimonial-q">
                        <img src="/images/q-t.png" alt="" width height />
                      </div>
                      <h2>Nullam egestas risus <br />interdum lectus</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. In vel lacus quis diam aliquam
                        maximus. Nullam egestas risus interdum
                        lectus porta ornare.</p>
                      <h4 className="hiddenDesktop">Ramkrishna <span>Marketing Head</span></h4>
                    </div>
                    <div className="col-sm-4">
                      <img src="/images/team01.png" className="t-img" width height alt="" />
                    </div>
                    <div className="col-sm-2 pl-0">
                      <h4 className="hiddenMobile">Ramkrishna <span>Marketing Head</span></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row m-0">
          <div className="col-sm-5 reviews">
            <div className="img">
              <img src="/images/glassdoor.png" className="glassdoor" alt="" width height />
              <span>Reviews</span>
            </div>
            <div className="rating">
              <div className="rating-img row align-items-center m-0">
                <img src="/images/star-fill.png" alt="" width height />
                <img src="/images/star-fill.png" alt="" width height />
                <img src="/images/star-fill.png" alt="" width height />
                <img src="/images/star-fill.png" alt="" width height />
                <img src="/images/star-grey.png" alt="" width height />
                <p className="pl-2">( 4/5 Ratings )</p>
              </div>
              <p><a href="/">Read all Reviews</a></p>
            </div>
          </div>
        </div>
      </section>
      <section className="culture-makers">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-7">
              <img src="/images/culture-img.jpg" />
            </div>
            <div className="col-md-5">
              <div className="culture-box">
                <a href="/">
                  <h3>Culture Makers lead, organizations grow twice as fast</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In vel lacus quis diam
                    aliquam maximus.</p>
                  <span className="go-btn">
                    <img src="/images/go-arrow.png" alt="" width height />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section className="box-layout">
          <div className="row align-items-center">
            <div className="col-md-5">
              <img src="/images/card-img1.jpg" />
            </div>
            <div className="col-md-3">
              <div className="center-div">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
            </div>
            <div className="col-md-4">
              <img src="/images/card-img2.jpg" />
            </div>
          </div>
        </section>
        <section className="join">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-9">
              <Link to="/offline-page/"><h2>Join the <br />family</h2></Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <footer>
        <div className="clear">
          <div className="top-menu">
            <ul className="text-center">
              <li><a href="/" title="Consumer Products">Consumer Products</a></li>
              <li><a href="/" title="Illumination">Illumination</a></li>
              <li><a href="/" title="EPC">EPC</a></li>
              <li><a href="/" title="Exports">Exports</a></li>
              <li><a href="/" title="Events">Events</a></li>
              <li><a href="/" title="Blog">Blog</a></li>
              <li><a href="/" title="Site Feedback">Site Feedback</a></li>
            </ul>
          </div>
          <div className="bottom-menu">
            <div className="row align-items-center">
              <div className="col-sm-9">
                <h3 className="toggle-menu">Quick Links</h3>
                <ul className="toggle-list">
                  <li><a href="/" title="Partners Login" className="pl-0">Partners Login</a></li>
                  <li><a href="/" title="Privacy">Privacy</a></li>
                  <li><a href="/" title="Terms and Conditions">Terms and Conditions</a></li>
                  <li><a href="/" title="Disclaimer">Disclaimer</a></li>
                  <li><a href="/" title="Testimonials">Testimonials</a></li>
                  <li><a href="/" title="Mode of Payments" className="border-none">Mode of Payments</a></li>
                </ul>
              </div>
              <div className="col-sm-3">
                <div className="social text-right">
                  <a href="https://www.facebook.com/bajajelectricals" className="facebook" title="Facebook" target="_blank"><img src="/images/facebook.png" width={32} height={32} alt="Facebook" /></a>
                  <a href="https://twitter.com/bajajelectrical" className="twitter" title="Twitter" target="_blank"><img src="/images/twitter.png" width={32} height={32} alt="Twitter" /></a>
                  <a href="https://www.youtube.com/user/BajajElectricalsABD" className="youtube" title="Youtube" target="_blank"><img src="/images/youtube.png" width={32} height={32} alt="Youtube" /></a>
                  <a href="https://www.instagram.com/bajajelectricals_ltd" className="instagram" title="Instagram" target="_blank"><img src="/images/instagram.png" width={32} height={32} alt="Instagram" /></a>
                  <a href="https://www.linkedin.com/company/bajajelectricals/" className="twitter" title="linkedin" target="_blank">
                    <img src="/images/linkedin.png" width={32} height={32} alt="linkedin" target="_blanck" />
                  </a>
                </div>
              </div>                    
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright text-center">
        <p>Copyright © Bajaj Electricals Ltd 2021</p>
      </section>
    </main>
  )
}

export default IndexPage
